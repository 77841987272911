<template>
    <section v-if="status == 'success' && items?.length! > 0">
        <GameHead title="Latest Pet Simulator 99 Items" icon="ps99.png" to="/ps-99/items" />
        <div class="relative mt-3.5">
            <Swiper :navigation="{ nextEl: '.ps99Next', prevEl: '.ps99Prev', disabledClass: 'carouselButtonDisabled' }" :modules="[SwiperNavigation]" :breakpoints="slideBreakpoints">
                <SwiperSlide v-for="item in items" :key="item.id">
                    <NuxtLink :to="`/ps-99/item/${item.id}`">
                        <Item :icon="item.icon" :name="item.name" :category="item.category" :rarity="item.rarity" :value="item.value" :date="item.createdAt" />
                    </NuxtLink>
                </SwiperSlide>
            </Swiper>
            <button class="carouselPrev ps99Prev">
                <i class="fa-solid fa-angle-left"></i>
            </button>
            <button class="carouselNext ps99Next">
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </div>
    </section>
</template>

<script setup lang="ts">
import GameHead from '../GameHead.vue'
import Item from '~/pages/(games)/ps-99/components/Item.vue'

interface ItemData {
    id: number;
    name: string;
    icon: string;
    category: string;
    rarity: string;
    value?: number;
    createdAt: string;
}

const { data: items, status } = await useFetch<ItemData[]>('/api/ps-99/latest/items')

defineProps({
    slideBreakpoints: {
        type: Object,
        required: true
    }
})
</script>