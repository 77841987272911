<template>
    <section v-if="status == 'success' && items?.length! > 0">
        <GameHead title="Latest Blade Ball Items" icon="bladeball.png" to="/bladeball" />
        <div class="relative mt-3.5">
            <Swiper :navigation="{ nextEl: '.bbNext', prevEl: '.bbPrev', disabledClass: 'carouselButtonDisabled' }" :modules="[SwiperNavigation]" :breakpoints="slideBreakpoints">
                <SwiperSlide v-for="item in items" :key="item.id">
                    <NuxtLink :to="`/bladeball/${item.category?.toLowerCase()}/${item.id}`">
                        <Item :icon="item.icon" :name="item.name" :rarity="item.rarity" :rap="item.rap" :value="item.value" :date="item.createdAt" />
                    </NuxtLink>
                </SwiperSlide>
            </Swiper>
            <button class="carouselPrev bbPrev">
                <i class="fa-solid fa-angle-left"></i>
            </button>
            <button class="carouselNext bbNext">
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </div>
    </section>
</template>

<script setup lang="ts">
import GameHead from '../GameHead.vue'
import Item from '~/pages/(games)/bladeball/components/Item.vue'

interface ItemData {
    id: number;
    name: string;
    icon: string;
    category?: string;
    rarity: string;
    tier: string;
    rap: number | null;
    value?: number;
    createdAt: string;
}

const { data: items, status } = await useFetch<ItemData[]>('/api/bladeball/latest/items')

defineProps({
    slideBreakpoints: {
        type: Object,
        required: true
    }
})
</script>