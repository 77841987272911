<template>
    <section v-if="status == 'success' && items?.length! > 0">
        <GameHead title="Latest Grand Piece Online Items" icon="gpo.png" to="/gpo/items" />
        <div class="relative mt-3.5">
            <Swiper :navigation="{ nextEl: '.gpoNext', prevEl: '.gpoPrev', disabledClass: 'carouselButtonDisabled' }" :modules="[SwiperNavigation]" :breakpoints="slideBreakpoints">
                <SwiperSlide v-for="item in items" :key="item.id">
                    <NuxtLink :to="`/gpo/item/${item.id}`">
                        <Item :icon="item.icon" :name="item.name" :category="item.category" :rarity="item.rarity" :tier="item.tier" :value="item.value" :date="item.createdAt" />
                    </NuxtLink>
                </SwiperSlide>
            </Swiper>
            <button class="carouselPrev gpoPrev">
                <i class="fa-solid fa-angle-left"></i>
            </button>
            <button class="carouselNext gpoNext">
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </div>
    </section>
</template>

<script setup lang="ts">
import GameHead from '../GameHead.vue'
import Item from '~/pages/(games)/gpo/components/Item.vue'

interface ItemData {
    id: number;
    name: string;
    icon: string;
    category: string;
    rarity: string;
    tier: string;
    value?: number;
    createdAt: string;
}

const { data: items, status } = await useFetch<ItemData[]>('/api/gpo/latest/items')

defineProps({
    slideBreakpoints: {
        type: Object,
        required: true
    }
})
</script>