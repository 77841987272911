<template>
    <section v-if="status == 'success' && skins?.length! > 0">
        <GameHead title="Latest Da Hood Skins" icon="da-hood.png" to="/da-hood/skins" />
        <div class="relative mt-3.5">
            <Swiper :navigation="{ nextEl: '.dhSkinsNext', prevEl: '.dhSkinsPrev', disabledClass: 'carouselButtonDisabled' }" :modules="[SwiperNavigation]" :breakpoints="slideBreakpoints">
                <SwiperSlide v-for="skin in skins" :key="skin.id">
                    <NuxtLink :to="`/da-hood/skin/${skin.id}`">
                        <Skin :icon="skin.icon" :name="skin.name" :category="skin.category" :value="skin.value" :date="skin.createdAt" />
                    </NuxtLink>
                </SwiperSlide>
            </Swiper>
            <button class="carouselPrev dhSkinsPrev">
                <i class="fa-solid fa-angle-left"></i>
            </button>
            <button class="carouselNext dhSkinsNext">
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </div>
    </section>
</template>

<script setup lang="ts">
import GameHead from '../GameHead.vue'
import Skin from '~/pages/(games)/da-hood/components/Skin.vue'

interface Skin {
    id: number;
    name: string;
    icon: string;
    category: string;
    value?: number;
    createdAt: string;
}

const { data: skins, status } = await useFetch<Skin[]>('/api/da-hood/latest/skins')

defineProps({
    slideBreakpoints: {
        type: Object,
        required: true
    }
})
</script>