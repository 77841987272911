<template>
    <div class="bg-[#1A1D2B] dark:bg-gradient-to-b dark:from-primary dark:to-primary text-sm text-center text-white font-medium py-4 px-6 lg:px-8" v-if="config.announcement_enabled">
        {{ config.announcement }}
    </div>
    <NuxtLayout name="ads">
        <header class="max-w-7xl text-center mx-auto">
            <h1 class="text-2xl lg:text-3xl text-gray-900 dark:text-white font-bold">The
                <span class="text-green-500 mx-1 font-extrabold text-3xl lg:text-4xl relative inline-block stroke-current">
                    BIGGEST
                    <svg class="absolute -bottom-0.5 w-full max-h-1.5" viewBox="0 0 55 5" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                        <path d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002" stroke-width="2"></path>
                    </svg>
                </span>
                Roblox Item Values Database
            </h1>
            <p class="mt-2 max-w-xl mx-auto text-md lg:text-lg font-medium text-gray-500 dark:text-gray-500">A multi-community driven source of reliable values, data, updates, leaks, trading information and more!</p>
            <NuxtLink :to="`https://discord.gg/${config.discord}`" target="_blank" external class="mt-4 flex items-center justify-center gap-x-2.5 bg-gradient-to-b from-[#728fd1] to-[#536ea7] hover:from-[#6885c7] hover:to-[#4c679f] text-center rounded-md shadow-sm w-fit py-3.5 px-6 mx-auto" v-if="config.discord">
                <span class="text-white text-sm font-medium">Join us on</span>
                <NuxtImg src="/images/discord.png" class="h-4" />
            </NuxtLink>
        </header>
        <div class="space-y-6" :class="{'mt-6': !config.discord, 'mt-10': config.discord}">
            <section>
                <h2 class="text-md text-gray-800 dark:text-white font-semibold">Games</h2>
                <div id="gamesCarousel" class="relative mt-2.5">
                    <Swiper :navigation="{ nextEl: '.gamesNext', prevEl: '.gamesPrev', disabledClass: 'carouselButtonDisabled' }" :modules="[SwiperNavigation]" :breakpoints="slideBreakpoints">
                        <SwiperSlide>
                            <NuxtLink to="/da-hood">
                                <div class="relative my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <div class="absolute right-2 top-2">
                                        <VerifiedBadge class="h-8" />
                                    </div>
                                    <NuxtImg src="/images/games/banners/da-hood.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <NuxtLink to="/bladeball">
                                <div class="relative my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <div class="absolute right-2 top-2">
                                        <VerifiedBadge class="h-8" />
                                    </div>
                                    <NuxtImg src="/images/games/banners/bladeball.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <NuxtLink to="/mm2">
                                <div class="my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <NuxtImg src="/images/games/banners/mm2.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <NuxtLink to="/gpo">
                                <div class="my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <NuxtImg src="/images/games/banners/grand-piece-online.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        <!--
                        <SwiperSlide>
                            <NuxtLink to="/psx">
                                <div class="my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <NuxtImg src="/images/games/banners/psx.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        -->
                        <SwiperSlide>
                            <NuxtLink to="/ps-99">
                                <div class="my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <NuxtImg src="/images/games/banners/ps99.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        <!--
                        <SwiperSlide>
                            <NuxtLink to="/hood-modded">
                                <div class="my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <NuxtImg src="/images/games/banners/hood-modded.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        -->
                        <!--
                        <SwiperSlide>
                            <NuxtLink to="/adopt-me">
                                <div class="relative my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <NuxtImg src="/images/games/banners/adopt-me.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <NuxtLink to="/anime-defenders">
                                <div class="my-[5px] transition-transform hover:translate-y-[-5px]">
                                    <NuxtImg src="/images/games/banners/anime-defenders.png" />
                                </div>
                            </NuxtLink>
                        </SwiperSlide>
                        -->
                    </Swiper>
                    <button class="carouselPrev gamesPrev">
                        <i class="fa-solid fa-angle-left"></i>
                    </button>
                    <button class="carouselNext gamesNext">
                        <i class="fa-solid fa-angle-right"></i>
                    </button>
                </div>
            </section>
            <LatestDaHoodSkins :slideBreakpoints="slideBreakpoints" />
            <LatestBladeBallItems :slideBreakpoints="slideBreakpoints" />
            <!--
            <LatestHoodModdedFX :slideBreakpoints="slideBreakpoints" />
            -->
            <LatestMM2Items :slideBreakpoints="slideBreakpoints" />
            <!--<LatestAdoptMeItems :slideBreakpoints="slideBreakpoints" />-->
            <LatestGPOItems :slideBreakpoints="slideBreakpoints" />
            <!--
            <LatestPSXItems :slideBreakpoints="slideBreakpoints" />
            -->
            <LatestPS99Items :slideBreakpoints="slideBreakpoints" />
            <!--
            <LatestAnimeDefendersItems :slideBreakpoints="slideBreakpoints" />
            -->
        </div>
    </NuxtLayout>
</template>

<script setup lang="ts">
import LatestDaHoodSkins from './components/sections/DaHood.vue'
import LatestBladeBallItems from './components/sections/BladeBall.vue'
//import LatestHoodModdedFX from './components/sections/HoodModded.vue'
import LatestAdoptMeItems from './components/sections/AdoptMe.vue'
import LatestMM2Items from './components/sections/MM2.vue'
import LatestGPOItems from './components/sections/GPO.vue'
//import LatestPSXItems from './components/sections/PSX.vue'
import LatestPS99Items from './components/sections/PS99.vue'
//import LatestAnimeDefendersItems from './components/sections/AnimeDefenders.vue'

const config = useState<Config>('config')

const slideBreakpoints = {
    1360: {
        spaceBetween: 15,
        slidesPerView: 5
    },
    870: {
        spaceBetween: 15,
        slidesPerView: 4
    },
    620: {
        spaceBetween: 15,
        slidesPerView: 3
    },
    0: {
        spaceBetween: 15,
        slidesPerView: 2
    }
}

definePageMeta({
    path: '/'
})
</script>

<style scoped>
#gamesCarousel .swiper-slide img {
    @apply rounded-md;
}
</style>